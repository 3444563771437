<template>
  <ul class="tw-space-y-2 my-4">
    <li v-for="(item, idx) of info" :key="idx" class="has-background-danger tw-p-4 tw-text-white tw-rounded-2xl">
      {{ item | format }}
      <slot name="action" />
    </li>
  </ul>
</template>

<script>
  import { STATUS_INFO_MAPPING } from '@/constants/COMMON';

  export default {
    props: {
      info: Array,
    },
    filters: {
      format: function (value) {
        if (value.type === 'missing_shipment_logic_rule_value') return value.message;
        else if (value.type && value.field) return STATUS_INFO_MAPPING[`${value.type}_${value.field}`];
        else if (value.message) return value.message;
        return 'Onbekend';
      },
    },
  };
</script>

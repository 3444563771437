<template>
  <div class="tw-group/copy">
    <div class="tw-relative tw-w-fit tw-inline-block">
      <slot></slot>
      <button
        class="button tw-hidden group-hover/copy:tw-inline-flex tw-absolute tw-top-1/2 tw-right-0 -tw-translate-y-1/2 tw-h-10 tw-w-10"
        @click="copy"
      >
        <font-awesome-icon fixed-width icon="copy" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { defineProps, getCurrentInstance } from 'vue';

  const props = defineProps({
    value: String,
    message: {
      type: String,
      default: 'Gekopieerd naar klembord',
    },
  });

  const { proxy } = getCurrentInstance();

  const copy = () => {
    proxy.$buefy.toast.open({
      message: props.message,
      type: 'is-success',
    });
    try {
      navigator.clipboard.writeText(props.value);
    } catch (error) {
      document.execCommand?.('copy', false, props.value);
      alert(
        'Je browser ondersteund geen "Navigator API": https://developer.mozilla.org/en-US/docs/Web/API/Navigator. ' +
          'De waarde is wel gekopieerd naar je klembord. Maar het is aan te raden om een moderne browser te gebruiken.',
      );
    }
  };
</script>

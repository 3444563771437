<template>
  <div>
    <base-tab
      ref="basetab"
      :default-sort="defaultSort"
      detailed
      item-name="retour"
      item-name-plural="retouren"
      :loadView="loadView"
      :updateView="updateView"
      :view="view"
      @select="selectedLabels = $event"
    >
      <template #controls>
        <b-button
          class="level-item responsive-button"
          :disabled="isUpdating"
          icon-left="sync"
          :loading="isUpdating"
          @click="update"
        >
          Track labels
        </b-button>
        <div class="level-item">
          <print-controls
            context="label"
            :label-ids="selectedLabels.map(label => label.id)"
            :order-ids="selectedLabels.map(label => label.order_id)"
          />
        </div>
        <b-dropdown :disabled="!selectedLabels.length">
          <template #trigger>
            <b-button icon-right="chevron-down">Meer acties…</b-button>
          </template>

          <b-dropdown-item
            aria-role="listitem"
            :disabled="isUpdating"
            :loading="isUpdating"
            style="display: flex; gap: 8px; align-items: center"
            @click="generatePackingLists(selectedLabels)"
          >
            <font-awesome-icon class="is-size-5" fixed-width icon="file-arrow-down" />
            Pakbonnen downloaden
          </b-dropdown-item>

          <b-dropdown-item
            aria-role="listitem"
            style="display: flex; align-items: center; gap: 8px"
            @click="deactivateBulkLabels(selectedLabels)"
          >
            <font-awesome-icon class="is-size-5" fixed-width icon="trash" />
            Retourlabels verwijderen
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #table>
        <b-table-column
          #default="{ row: label }"
          cell-class="is-vcentered is-truncated is-15"
          field="order_number"
          label="Ordernummer"
          numeric
          sortable
        >
          <Copy :message="`${label.order_number} gekopieerd naar klembord`" :value="label.order_number">
            <span class="tw-inline-block tw-max-w-[16ch] tw-tabular-nums tw-overflow-hidden tw-text-ellipsis">
              {{ label.order_number }}
            </span>
          </Copy>
        </b-table-column>
        <b-table-column #default="{ row: label }" cell-class="is-vcentered" field="status" label="Status" sortable>
          <label-status :label="label" />
        </b-table-column>
        <b-table-column #default="{ row: label }" cell-class="is-vcentered buttons is-right">
          <!-- Toggle in separete column (not in tracking-number). This solves numerous alignment issues and still looks OK if no label is multicolli -->
          <!-- TODO: How's a11y here? -->
          <a v-if="label.shipment.packages.length > 1" class="button is-text is-small" @click="togglePackages(label)">
            <b-icon :icon="isVisiblePackages(label) ? 'chevron-up' : 'chevron-down'"></b-icon>
          </a>
        </b-table-column>
        <b-table-column
          #default="{ row: label }"
          cell-class="is-vcentered is-truncated is-15"
          field="tracking_number"
          label="Trackingnummer"
        >
          <tracking-number
            v-if="label.shipment.tracking_url"
            :tracking-number="label.shipment.tracking_number"
            :tracking-url="label.shipment.tracking_url"
          />
          <tracking-number
            v-else
            :tracking-number="label.shipment.packages[0].tracking_number"
            :tracking-url="label.shipment.packages[0].tracking_url"
          />
        </b-table-column>
        <b-table-column
          #default="{ row: label }"
          cell-class="is-vcentered"
          field="created_at"
          label="Aangemaakt"
          numeric
          sortable
        >
          {{ getCreatedAtTimeStamp(label) | humanizeDate({ day: 'numeric', month: 'short', year: 'numeric' }) }}
        </b-table-column>
        <b-table-column
          v-slot="{ row: label }"
          cell-class="is-vcentered"
          field="label_marker"
          label="Markering"
          sortable
          style="overflow: auto"
        >
          {{ label.marker || 'N/A' }}
        </b-table-column>
        <b-table-column
          v-slot="{ row: label }"
          cell-class="is-vcentered"
          field="payment.payment_method"
          label="Betaalmethode"
          sortable
        >
          {{ label.payment?.payment_method | formatPaymentMethod }}
        </b-table-column>
        <b-table-column
          #default="{ row: label }"
          cell-class="!tw-border-l-2 tw-w-14 tw-space-x-2 !tw-border-l group-[.odd]:tw-bg-[#fafafa] group-hover:!tw-bg-[whitesmoke] tw-sticky tw-right-0 tw-z-10 tw-bg-white"
          header-class="!tw-border-l-2 tw-w-14 tw-space-x-2 !tw-border-l group-[.odd]:tw-bg-[#fafafa] group-hover:!tw-bg-[whitesmoke] tw-sticky tw-right-0 tw-z-10 !tw-bg-white"
          label="Acties"
        >
          <b-button
            aria-label="details"
            class="is-small"
            tag="router-link"
            title="details"
            :to="{ name: 'label', params: { labelId: label.id }, query: { is_returned: '1' } }"
          >
            <font-awesome-icon class="icon" fixed-width icon="eye" />
          </b-button>

          <!-- marking -->
          <b-dropdown append-to-body position="is-bottom-left">
            <template #trigger>
              <b-button aria-label="markeren" size="is-small" title="markeren">
                <font-awesome-icon class="icon" fixed-width icon="flag" />
              </b-button>
            </template>
            <!--            <b-dropdown-item style="display: flex; gap: 8px; align-items: center">-->
            <!--              <font-awesome-icon class="has-text-success" icon="check" />-->
            <!--              Retour bevestigen-->
            <!--            </b-dropdown-item>-->
            <!--            <b-dropdown-item style="display: flex; gap: 8px; align-items: center">-->
            <!--              <font-awesome-icon class="has-text-warning" icon="exclamation-triangle" />-->
            <!--              Actie vereist</b-dropdown-item-->
            <!--            >-->
            <!--            <b-dropdown-item style="display: flex; gap: 8px; align-items: center">-->
            <!--              <font-awesome-icon class="has-text-danger" icon="times" />-->
            <!--              Item ontbreekt</b-dropdown-item-->
            <!--            >-->
            <!--            <b-dropdown-item style="display: flex; gap: 8px; align-items: center">-->
            <!--              <font-awesome-icon class="has-text-danger" icon="times" />-->
            <!--              Retour weigeren-->
            <!--            </b-dropdown-item>-->
            <b-dropdown-item
              v-for="option in MARKING_OPTIONS"
              :key="option.text"
              style="display: flex; gap: 8px; align-items: center"
              @click="updateMark(option.text, label)"
            >
              <font-awesome-icon :class="`has-text-${option.colorVariant} is-size-5`" fixed-width :icon="option.icon" />
              {{ option.text }}
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown append-to-body class="!tw-m-0" position="is-bottom-left">
            <template #trigger>
              <button aria-label="acties" class="button is-small" title="acties">
                <font-awesome-icon class="icon" fixed-width icon="ellipsis-v" />
              </button>
            </template>

            <b-dropdown-item v-if="!getTicket(label)" aria-role="listitem" has-link>
              <router-link
                style="display: flex; gap: 8px; align-items: center"
                :to="{ name: 'create_ticket', params: { labelId: label.id } }"
              >
                <font-awesome-icon class="is-size-5" fixed-width icon="bolt-lightning" />
                Onderzoek aanvragen
              </router-link>
            </b-dropdown-item>

            <b-dropdown-item
              aria-role="listitem"
              style="display: flex; gap: 8px; align-items: center"
              @click="newOrderFromLabel(label)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="copy" />
              Retourlabel dupliceren naar bestellingen
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              style="display: flex; gap: 8px; align-items: center"
              @click="deactivateLabel(label)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="trash" />
              Retour label verwijderen
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </template>
      <template #table-detail="{ row: label }">
        <tr v-for="packageItem in label.shipment.packages.slice(1)" :key="packageItem.tracking_number">
          <td colspan="4">&nbsp;<!-- Simple solution to make sure these rows have the correct height --></td>
          <td class="is-truncated is-15">
            <tracking-number :tracking-number="packageItem.tracking_number" :tracking-url="packageItem.tracking_url" />
          </td>
          <td colspan="4"></td>
        </tr>
      </template>
    </base-tab>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import BaseTab from './BaseTab';
  import LabelMixin from '@/components/mixins/LabelMixin.js';
  import { getMoment } from '@/utils/functions';
  import { ApiError } from '@/utils/errors';
  import PrintControls from '@/components/PrintControls.vue';
  import TrackingNumber from '@/components/properties/TrackingNumber.vue';
  import LabelStatus from '@/components/properties/LabelStatus.vue';
  import Copy from '@/components/properties/Copy.vue';

  const MARKING_OPTIONS = [
    { text: 'Retour bevestigen', colorVariant: 'success', icon: 'check-circle' },
    { text: 'Actie vereist', colorVariant: 'warning', icon: 'exclamation-triangle' },
    { text: 'Item ontbreekt', colorVariant: 'danger', icon: 'times-circle' },
    { text: 'Retour weigeren', colorVariant: 'danger', icon: 'times-circle' },
  ];

  export default {
    components: { Copy, TrackingNumber, PrintControls, BaseTab, LabelStatus },
    mixins: [LabelMixin],
    props: {
      defaultSort: Array,
      labelFilter: Object,
      labelTimestamp: [String, Function],
      viewName: {
        type: String,
        default: 'labelTab',
      },
    },
    data() {
      return {
        isUpdating: false,
        selectedLabels: [],
      };
    },
    computed: {
      ...mapState({
        view(state) {
          return state.label.views[this.viewName];
        },
      }),

      MARKING_OPTIONS() {
        return MARKING_OPTIONS;
      },
    },
    filters: {
      /**
       * Can be organisation_paid | consumer_paid | store_credit
       * Translates to human-readable format
       * @param paymentMethod
       */
      formatPaymentMethod(paymentMethod) {
        switch (paymentMethod) {
          case 'organisation_paid':
            return 'Gefactureerd';
          case 'consumer_paid':
            return 'Klant betaald';
          case 'store_credit':
            return 'Winkelkrediet';
          default:
            return 'N/A';
        }
      },
    },
    methods: {
      togglePackages(row) {
        this.$refs.basetab.toggleDetails(row);
      },
      isVisiblePackages(row) {
        return this.$refs.basetab.isVisibleDetailRow(row);
      },

      getTicket(label) {
        return label.ticket ? Object.keys(label.ticket).length > 0 : null;
      },
      updateView() {
        this.$store.dispatch('label/updateView', { view: this.viewName });
      },
      async updateMark(markText, label) {
        //if the label is already marked with the same marker, do nothing
        if (markText !== label.marker) {
          this.isUpdating = true;
          try {
            await this.$store.dispatch('label/updateLabel', {
              id: label.id,
              action: markText,
            });
            this.isUpdating = false;
            this.loadView();
          } catch (error) {
            this.isUpdating = false;
            throw new ApiError('Er is een fout opgetreden bij het markeren van het label. Probeer het later opnieuw.');
          }
        }
      },
      loadView(page, filter) {
        this.$store.dispatch('label/loadView', {
          view: this.viewName,
          filter: {
            ...filter,
            ...this.labelFilter,
            page,
          },
        });
      },

      getOrderItemsString(orderItemsArray) {
        let orderItemsArrayString = '';
        orderItemsArray.forEach(element => {
          orderItemsArrayString += element.name + '\n';
        });
        return orderItemsArrayString;
      },

      async update() {
        try {
          await this.$store.dispatch('label/update');
        } finally {
          this.isUpdating = false;
        }
      },
      getLabelTimestamp(label) {
        if (this.labelTimestamp instanceof Function) return getMoment(this.labelTimestamp(label));
        else if (this.labelTimestamp instanceof String) return getMoment(label[this.labelTimestamp]);
        else if (label.tracking_date) return getMoment(label.tracking_date);
        return getMoment(label.updated_at);
      },
      getCreatedAtTimeStamp(label) {
        return getMoment(label.created_at);
      },
    },
  };
</script>

<template>
  <span class="shipment-type">
    <template v-if="courier">
      <b-tooltip append-to-body :label="courier.service_name" position="is-right" type="is-dark">
        <figure class="image">
          <img v-if="meta && meta.images.mark" :alt="courier.service_name" :src="meta.images.mark" />
        </figure>
      </b-tooltip>
      <template v-if="format === 'long'">
        {{ courier.service_name }}
        <template v-if="shipmentType">
          {{ shipmentType.name }}
        </template>
      </template>
      <template v-else-if="format === 'short'">
        <template v-if="shipmentType">
          {{ shipmentType.name }}
        </template>
        <template v-else>
          {{ courier.service_name }}
        </template>
      </template>
      <b-tooltip
        v-if="!courier.is_active && showWarnings"
        append-to-body
        label="Deze vervoerder is niet actief!"
        position="is-right"
        type="is-dark"
      >
        <span class="icon has-text-danger">
          <i aria-hidden="true" class="gg-danger"></i>
          <span class="is-sr-only">Verwijderd</span>
        </span>
      </b-tooltip>
    </template>
    <b-skeleton v-if="loading && !courier" :animated="true"></b-skeleton>
  </span>
</template>

<script>
  export default {
    props: {
      item: Object,
      format: {
        type: String,
        default: 'long',
      },
      showWarnings: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      loading() {
        return this.$store.state.courier.loading;
      },
      courier() {
        return this.$store.getters['courier/courierById'](this.item?.shipment?.courier_id || this.item?.service_id);
      },
      meta() {
        return this.$store.state.courier.meta.find(c => c.class == this.courier.service_class);
      },
      shipmentType() {
        if (!this.courier) return;

        return this.courier.shipment_types.find(t => t.id === this.item?.shipment?.shipment_type);
      },
      isInactive() {
        return !this.courier || !this.courier.is_active || !this.shipmentType;
      },
    },
  };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-w-fit tw-relative"},[_c('b-tooltip',{attrs:{"active":!!_setup.tooltip,"append-to-body":"","multilined":"","type":_setup.cn({
        'is-danger': _setup.isError,
        'is-warning': _setup.isWarning,
        'is-success': _setup.isSuccess,
        'is-dark': !_setup.isError && !_setup.isWarning && !_setup.isSuccess,
      })},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"tag tw-relative tw-inline-flex tw-gap-2 tw-items-center",class:{
          'is-danger': _setup.isError,
          'is-warning': _setup.isWarning,
          'is-success': _setup.isSuccess,
          'tw-cursor-help': !!_setup.tooltip,
        }},[_vm._v(" "+_vm._s(_setup.text)+" ")])]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_setup.tooltip)+" ")]},proxy:true}])}),(_setup.hasStatusInfo)?_c('span',{staticClass:"tw-bg-zinc-700 tw-cursor-help tw-absolute tw-text-white -tw-top-2 tw-left-[calc(100%-0.5rem)] tw-font-mono tw-rounded-full tw-text-xs"},[_c('b-tooltip',{attrs:{"append-to-body":"","multilined":"","type":"is-dark"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"tw-px-1.5 tw-h-5 tw-leading-none tw-grid tw-place-items-center"},[_vm._v(" "+_vm._s(_vm.order.status_info.length)+" ")])]},proxy:true},{key:"content",fn:function(){return [_c('ol',{staticClass:"tw-font-sans tw-flex-col tw-flex tw-text-left tw-list-disc tw-list-outside tw-ml-4"},_vm._l((_vm.order.status_info),function(value,index){return _c('li',{key:index},[(value.type === 'missing_shipment_logic_rule_value')?_c('span',[_vm._v(_vm._s(value.message))]):(value.type && value.field)?_c('span',[_vm._v(" "+_vm._s(_setup.STATUS_INFO_MAPPING[`${value.type}_${value.field}`])+" ")]):(value.message)?_c('span',[_vm._v(_vm._s(value.message))]):_c('span',[_vm._v("Onbekend")])])}),0)]},proxy:true}],null,false,3594085539)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
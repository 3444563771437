var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-tooltip',{attrs:{"active":!!_setup.tooltip,"append-to-body":"","multilined":"","type":_setup.cn({
      'is-danger': _setup.isError,
      'is-warning': _setup.isWarning,
      'is-success': _setup.isSuccess,
      'is-dark': !_setup.isError && !_setup.isWarning && !_setup.isSuccess,
    })},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"tag",class:{
        'tw-cursor-help': !!_setup.tooltip,
        'is-danger': _setup.isError,
        'is-warning': _setup.isWarning,
        'is-success': _setup.isSuccess,
      }},[_vm._v(" "+_vm._s(_setup.text)+" ")])]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_setup.tooltip)+" ")]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="label">
    <b-loading v-model="isLoading" :is-full-page="false" />
    <div class="tw-container tw-max-w-none lg:tw-max-w-screen-3xl tw-p-8">
      <header class="tw-space-y-8">
        <nav class="tw-space-y-8">
          <b-button icon-left="arrow-left" @click="$router.backOrDefault(defaultReturnRoute)"> Terug</b-button>
          <h1 id="shipment-title" class="title">Zendingdetails</h1>
        </nav>
        <div class="tw-flex tw-flex-wrap tw-gap-4 tw-items-center">
          <section>
            <print-controls context="label" :label-ids="[label.id]" :order-ids="[label.order_id]"></print-controls>
          </section>
          <section class="tw-space-x-4">
            <div v-if="label.order_items && label.validators && label.validators.is_export" class="level-item">
              <b-button
                :disabled="printExportMutex"
                icon-left="software-download"
                :loading="printExportMutex"
                @click="printExportDoc(label.id, 'pdf')"
              >
                Export PDF
              </b-button>
            </div>
            <b-tooltip :active="!canCreateReturnLabel" append-to-body multilined type="is-warning">
              <template #content>
                Je mag voor
                <span class="has-text-weight-bold">{{ courier?.service_name }}</span> bestellingen die gaan naar
                <span class="has-text-weight-bold">{{ label.country }}</span> geen retourlabels aanmaken.
              </template>
              <b-button
                :disabled="!canCreateReturnLabel || isCreatingReturnLabel"
                :loading="isCreatingReturnLabel"
                @click="createReturnLabel({ order_ids: [label.order_id] })"
              >
                Retourlabel maken
              </b-button>
            </b-tooltip>
            <b-dropdown append-to-body position="is-bottom-left">
              <template #trigger>
                <b-button>
                  Meer acties…
                  <font-awesome-icon fixed-width icon="chevron-down" />
                </b-button>
              </template>

              <b-dropdown-item
                aria-role="listitem"
                style="display: flex; align-items: center; gap: 8px"
                @click="newOrderFromLabel(label)"
              >
                <font-awesome-icon class="is-size-5" fixed-width icon="copy" />
                Label dupliceren naar bestellingen
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                style="display: flex; align-items: center; gap: 8px"
                @click="deactivateLabel(label, true)"
              >
                <font-awesome-icon class="is-size-5" fixed-width icon="trash" />
                Label verwijderen
              </b-dropdown-item>

              <template v-if="isAllowedToTicket">
                <b-dropdown-item v-if="!ticket" aria-role="listitem" has-link>
                  <router-link
                    style="display: flex; align-items: center; gap: 8px"
                    :to="{ name: 'create_ticket', params: { labelId: label.id } }"
                  >
                    <font-awesome-icon class="is-size-5" fixed-width icon="bolt-lightning" />
                    <span>Onderzoek aanvragen</span>
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item v-else aria-role="listitem" has-link>
                  <router-link :to="{ name: 'ticket-single', params: { ticketId: label.ticket.ticket_id } }">
                    <font-awesome-icon class="is-size-5" fixed-width icon="bolt-lightning" />
                    <span>Naar onderzoek</span>
                  </router-link>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </section>
        </div>
      </header>

      <status-info
        v-if="label.status_info"
        container-class="block"
        :info="label.status_info"
        item-class="notification is-danger"
      >
        <template
          v-if="label.status_info?.find(item => item.status === 'warning' && item.type === 'order_update_failed')"
          #action
        >
          <b-button class="is-primary" inverted outlined @click="retry">Probeer opnieuw</b-button>
        </template>
      </status-info>

      <hr />

      <main
        class="tw-grid tw-grid-rows-[auto,auto,auto,auto] tw-gap-8 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4"
      >
        <section class="tw-row-span-full tw-space-y-4">
          <tracking-history :label="label" />
        </section>

        <section>
          <h2 class="tw-text-2xl tw-font-semibold">Ontvanger</h2>
          <div>
            <div class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2">
              <div class="tw-text-sm tw-text-neutral-500">Naam</div>
              <div class="tw-text-neutral-950">{{ label.name }}</div>
            </div>

            <div
              v-if="label.company_name"
              class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
            >
              <div class="tw-text-sm tw-text-neutral-500">Bedrijf</div>
              <div class="tw-text-neutral-950">{{ label.company_name }}</div>
            </div>

            <div class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2">
              <div class="tw-text-sm tw-text-neutral-500">Adres</div>
              <div class="tw-text-neutral-950">
                <address class="tw-not-italic">
                  {{ label.address_1 }} {{ label.housenumber }}<br />
                  <template v-if="label.address_2">{{ label.address_2 }}<br /></template>
                  {{ label.zipcode }} {{ label.city }}<br />
                  <country v-model="label.country" />
                </address>
              </div>
            </div>

            <div
              v-if="label.email"
              class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
            >
              <div class="tw-text-sm tw-text-neutral-500">E-mail</div>
              <div class="tw-text-neutral-950">
                <a :href="`mailto:${label.email}`">{{ label.email }}</a>
              </div>
            </div>

            <div
              v-if="label.phone"
              class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
            >
              <div class="tw-text-sm tw-text-neutral-500">Telefoonnummer</div>
              <div class="tw-text-neutral-950">{{ label.phone }}</div>
            </div>
          </div>
          <template v-if="cameFromReturn && !!label.reason_retour">
            <h2 class="title is-4">Retour redenen</h2>
            <ul class="tw-list-disc tw-list-inside">
              <template v-for="(value, key) in label.reason_retour">
                <li v-if="value" :key="key">
                  {{ REASON_MAPPING[key] }}<template v-if="typeof value === 'string'">: {{ value }}</template>
                </li>
              </template>
            </ul>
          </template>
        </section>

        <section class="tw-space-y-4">
          <h2 class="tw-text-2xl tw-font-semibold">Zending</h2>
          <div class="tw-space-y-4">
            <div class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-4 tw-p-2 -tw-m-2">
              <p class="tw-text-sm tw-text-neutral-500">Verzendmethode</p>
              <p class="tw-text-neutral-950">
                <courier :item="label" :show-warnings="false" />
              </p>
            </div>

            <div class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2">
              <p class="tw-text-sm tw-text-neutral-500">Status</p>
              <label-status :label="label" />
            </div>

            <div
              v-if="label.shipment && label.shipment.tracking_url"
              class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
            >
              <p class="tw-text-sm tw-text-neutral-500">Track zending</p>
              <p class="tw-text-neutral-950">
                <tracking-number
                  :tracking-number="label.shipment.tracking_number"
                  :tracking-url="label.shipment.tracking_url"
                />
              </p>
            </div>

            <!-- TODO: Find a better solution for these nested properties. They don't look right -->
            <div
              v-if="label?.shipment?.courier_fields && Object.keys(label.shipment.courier_fields).length && courierMeta"
              class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
            >
              <p class="tw-text-sm tw-text-neutral-500">Gekozen verzendopties</p>
              <ul v-if="courierFields.length" class="tw-grid tw-gap-1">
                <li v-for="field in courierFields" :key="field.id" class="tag tw-w-fit is-primary">
                  {{ field.props?.label || field.name || field.id }}
                </li>
              </ul>
              <div v-else class="value">Geen verzendopties gekozen</div>
            </div>
          </div>

          <div v-if="label.shipment" class="tw-max-h-96 tw-overflow-y-auto px-2 -tw-mx-2">
            <div v-for="(package_, idx) in label.shipment.packages" :key="`package-${idx}`" class="tw-space-y-2">
              <h3 class="tw-text-xl tw-font-semibold">
                Pakket
                <span v-if="label.shipment.packages.length > 1">{{ idx + 1 }}</span>
              </h3>

              <div class="tw-space-y-1">
                <div
                  class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
                >
                  <p class="tw-text-sm tw-text-neutral-500">Gewicht</p>
                  <p v-if="package_.weight" class="tw-text-neutral-950">{{ package_.weight }} gram</p>
                  <p v-else>–</p>
                </div>
                <div
                  v-if="package_.tracking_url"
                  class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
                >
                  <p class="tw-text-sm tw-text-neutral-500">Tracking</p>
                  <tracking-number :tracking-number="package_.tracking_number" :tracking-url="package_.tracking_url" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2 class="tw-text-2xl tw-font-semibold">Order</h2>
          <div
            v-if="label.order_number"
            class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
          >
            <div class="tw-text-sm tw-text-neutral-500">Ordernummer</div>
            <div class="tw-text-neutral-950">{{ label.order_number }}</div>
          </div>

          <div
            v-if="label.order_date"
            class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
          >
            <div class="tw-text-sm tw-text-neutral-500">Orderdatum</div>
            <div class="tw-text-neutral-950">{{ label.order_date | formatTimestamp }}</div>
          </div>

          <div
            v-if="label.order_status"
            class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
          >
            <div class="tw-text-sm tw-text-neutral-500">Shop Status</div>
            <div class="tw-text-neutral-950">{{ label.order_status }}</div>
          </div>

          <div v-if="label.invoice_items">
            <b-collapse animation="slide" aria-id="invoice_items_collapse" :open="false">
              <template #trigger="props">
                <a aria-controls="invoice_items_collapse" :aria-expanded="props.open" class="block">
                  <h2>
                    <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
                    Prijs en facturatie
                  </h2>
                </a>
              </template>
              <div v-for="(price, idx) in label.invoice_items" :key="'invoice_item' + idx" class="block">
                <v-with
                  v-slot="{ total }"
                  :total="price.parts.reduce((acc, value) => acc + parseFloat(value.unit_price), 0)"
                >
                  <table class="table is-striped is-hoverable is-narrow is-fullwidth">
                    <thead>
                      <tr>
                        <th v-if="price.type === 'label_price'" colspan="2">Labelprijs</th>
                        <th v-else-if="price.type === 'surcharge'" colspan="2">Naheffing</th>
                        <th v-else-if="price.type === 'refund'" colspan="2">Terugbetaling</th>
                        <th v-else colspan="2">Onbekend factuurtype</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in price.parts" :key="'invoice_item' + idx + item.id">
                        <td>{{ item.description }}</td>
                        <td class="has-text-right">{{ item.unit_price | formatMoney }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Totaal</th>
                        <th class="has-text-right">{{ total | formatMoney }}</th>
                      </tr>
                    </tfoot>
                  </table>
                </v-with>
              </div>
            </b-collapse>
          </div>
        </section>

        <section v-if="order_items && order_items.length > 0" class="xl:tw-col-start-2 tw-col-span-full">
          <hr class="seperator" />
          <h2 class="title is-4">
            {{ cameFromReturn ? 'Geretourneerde producten' : 'Orderregels' }}
          </h2>

          <div v-if="order_items.some(item => item.is_dummy_item)">
            <b-notification aria-close-label="Close notification" :closable="false" type="is-info">
              Innosend heeft voor deze order fictieve gegevens toegevoegd om te voldoen aan de minimale
              productinformatie-eisen van Fedex.
            </b-notification>
          </div>

          <b-table :data="order_items" hoverable mobile-cards striped>
            <b-table-column v-slot="props" field="name" label="Naam">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="description" label="Omschrijving">
              {{ props.row.description }}
            </b-table-column>
            <b-table-column v-slot="props" field="quantity" label="Aantal" numeric>
              {{ props.row.quantity }}
            </b-table-column>
            <b-table-column v-slot="props" field="unit_price_inc_btw" label="Prijs per product" numeric>
              {{ props.row.unit_price_inc_btw | formatMoney(label.currency || 'EUR') }}
            </b-table-column>
            <b-table-column v-slot="props" field="weight" label="Gewicht per product" numeric>
              {{ humanizeUnit(props.row.weight, 'gram') }}
            </b-table-column>
            <b-table-column v-slot="props" field="category" label="Categorie">
              {{ props.row.category }}
            </b-table-column>
            <b-table-column v-slot="props" field="sku" label="SKU-code" numeric>
              {{ props.row.sku }}
            </b-table-column>
            <b-table-column v-slot="props" field="hs_code" label="HS-code" numeric>
              {{ props.row.hs_code }}
            </b-table-column>
            <b-table-column v-slot="props" field="product_country" label="Land van herkomst">
              <country :value="props.row.product_country" />
            </b-table-column>
          </b-table>
        </section>
      </main>
    </div>

    <form-page-footer>
      <b-button type="is-primary" @click="$router.backOrDefault(defaultReturnRoute)"> OK</b-button>
    </form-page-footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import PrintControls from '@/components/PrintControls.vue';
  import LabelMixin from '@/components/mixins/LabelMixin.js';
  import Country from '@/components/properties/Country';
  import Courier from '@/components/properties/Courier';
  import TrackingNumber from '@/components/properties/TrackingNumber';
  import StatusInfo from '@/components/properties/StatusInfo';
  import { download } from '@/utils/functions';
  import { ApiError } from '@/utils/errors';
  import { REASON_MAPPING } from '../constants/REASON_MAPPING';
  import LabelStatus from '@/components/properties/LabelStatus.vue';
  import FormPageFooter from '@/components/form/FormPageFooter.vue';
  import TrackingHistory from '@/components/TrackingHistory.vue';

  export default {
    components: {
      TrackingHistory,
      FormPageFooter,
      LabelStatus,
      Country,
      Courier,
      PrintControls,
      StatusInfo,
      TrackingNumber,
    },
    mixins: [LabelMixin],
    data() {
      return {
        selectedPackage: null,
        labelId: this.$route.params.labelId,
        isLoading: false,
        printExportMutex: false,
        label: null,
        defaultReturnRoute: { name: 'dashboard', params: { activeTabName: 'labels' } },
      };
    },

    computed: {
      REASON_MAPPING() {
        return REASON_MAPPING;
      },
      TRACKING_STATUS_MAPPING() {
        return TRACKING_STATUS_MAPPING;
      },
      moment() {
        return moment;
      },
      ...mapState({
        organisation: state => state.account.organisation,
      }),

      cameFromReturn() {
        return this.$route.query?.is_returned === '1';
      },
      order_items() {
        return this.label.order_items?.filter(item => (!this.cameFromReturn ? item : !!item.is_returned));
      },
      courier() {
        return this.$store.getters['courier/courierById'](this.label.courier_id || this.label.service_id);
      },
      courierMeta() {
        return this.$store.state.courier.meta.find(c => c.class == this.courier.service_class);
      },

      isAllowedToTicket() {
        return (
          process.env.VUE_APP_IS_UVDESK_ENABLED &&
          this.label.invoice_items?.find(item => item.type === 'label_price') !== undefined
        );
      },
      ticket() {
        const ticket = this.label.ticket;
        return ticket ? Object.keys(ticket).length > 0 : null;
      },

      courierFields() {
        let fields = [];
        if (this.courierMeta) {
          for (const fieldName in this.label.shipment.courier_fields) {
            const value = this.label.shipment.courier_fields[fieldName];
            if (value && value != 0) {
              fields.push({
                id: fieldName,
                props: this.courierMeta.order_fields.find(f => f.name == fieldName),
                value: value,
              });
            }
          }
        }
        return fields;
      },
    },
    methods: {
      /**
       * Inserts hardcoded events for internal things such as order creation.
       * Sorts the history by first_seen_at date in ascending order
       *
       * @example history item
       * {
       *   "status": "ready",
       *   "courier_description": "Bestelling nog niet gereed voor verzending",
       *   "location": {
       *     "name": "Warehouse A",
       *     "address_1": "Main St",
       *     "address_2": null,
       *     "housenumber": "123",
       *     "zipcode": "1000AA",
       *     "city": "Amsterdam",
       *     "country": "Netherlands",
       *     "latitude": 52.3676,
       *     "longitude": 4.9041
       *   },
       *   "first_seen_at": new ISODate("2024-08-20T07:00:00.000Z"),
       *   "last_seen_at": new ISODate("2024-08-20T07:30:00.000Z"),
       *   "email": {
       *     "sent_at": new ISODate("2024-08-20T07:05:00.000Z"),
       *     "system": "default"
       *   }
       * },
       *
       * @param history
       */
      getHistory(tracking) {
        const historyCopy = structuredClone(tracking.history);

        // Insert hardcoded events for internal things such as order creation
        historyCopy.push({
          status: 'order_created',
          location: null,
          first_seen_at: this.label.order_date,
          last_seen_at: this.label.order_date,
        });

        if (this.ticket) {
          historyCopy.push({
            status: 'ticket_created',
            location: null,
            first_seen_at: this.ticket.created_at,
            last_seen_at: this.ticket.created_at,
          });
        }

        //in the history copy we need to find the status ready and printed and look at the first_seen_at date, if they are within a minute we can combine them
        const readyEvent = historyCopy.find(item => item.status === 'ready');
        const printedEvent = historyCopy.find(item => item.status === 'printed');

        if (readyEvent && printedEvent) {
          const readyDate = new Date(readyEvent.first_seen_at);
          const printedDate = new Date(printedEvent.first_seen_at);
          if (Math.abs(readyDate - printedDate) < 60000) {
            // remove ready item and update status of printed item to ready_printed
            printedEvent.status = 'ready_printed';
            historyCopy.filter(item => item !== readyEvent);
          }
        }

        if (tracking.delivery && !historyCopy.some(item => item.status === 'delivered')) {
          historyCopy.push({
            status: 'estimated_delivery',
            location: null,
            first_seen_at: tracking.delivery.start,
            last_seen_at: tracking.delivery.end,
          });
        }

        // Sort by first_seen_at date in ascending order
        historyCopy.sort((a, b) => new Date(a.first_seen_at) - new Date(b.first_seen_at));

        return historyCopy;
      },
      async retry() {
        this.isLoading = true;
        await this.$store.dispatch('label/retryOrderUpdate', [this.label.id]);
        await this.loadLabel(true);
        this.isLoading = false;
      },

      translateMailStatuses(status) {
        const mapping = {
          printed: 'Bestelling verwerkt mail verzonden',
          transit: 'Bestelling ontvangen mail verzonden',
          courier: 'Bezorger onderweg mail verzonden',
          delivered: 'Bestelling bezorgd mail verzonden',
          delivered_servicepoint: 'Bestelling afgeleverd servicepunt mail verzonden',
          return_from_delivery: 'Fout bij levering mail verzonden',
        };
        return mapping[status];
      },
      async printExportDoc(labelId) {
        this.printExportMutex = true;
        const doc = await this.$store.dispatch('label/getExportDoc', { labelId });
        download('export_doc.pdf', doc, 'application/pdf', 'base64');
        this.printExportMutex = false;
      },

      async loadLabel(forceReload = false) {
        this.isLoading = true;
        try {
          this.label = await this.$store.dispatch('label/getLabel', { labelId: this.labelId, forceReload });
          this.selectedPackage = this.label.shipment?.packages?.[0];
        } catch (error) {
          throw new ApiError();
        }
        this.isLoading = false;
      },

      formatValue(value) {
        if (value === true) return 'Ja';
        else if (value === false) return 'Nee';
        else if (!value) return '-';
        return value;
      },
      humanizeUnit(value, unit) {
        return Intl.NumberFormat('nl-NL', { style: 'unit', unit }).format(value);
      },
    },
    created() {
      this.loadLabel();
    },
    beforeRouteUpdate(to, from, next) {
      this.labelId = to.params.labelId;
      next();
    },
  };
</script>

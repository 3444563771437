<template>
  <div class="tw-w-fit tw-relative">
    <b-tooltip
      :active="!!tooltip"
      append-to-body
      multilined
      :type="
        cn({
          'is-danger': isError,
          'is-warning': isWarning,
          'is-success': isSuccess,
          'is-dark': !isError && !isWarning && !isSuccess,
        })
      "
    >
      <template #default>
        <span
          class="tag tw-relative tw-inline-flex tw-gap-2 tw-items-center"
          :class="{
            'is-danger': isError,
            'is-warning': isWarning,
            'is-success': isSuccess,
            'tw-cursor-help': !!tooltip,
          }"
        >
          {{ text }}
        </span>
      </template>
      <template #content>
        {{ tooltip }}
      </template>
    </b-tooltip>
    <span
      v-if="hasStatusInfo"
      class="tw-bg-zinc-700 tw-cursor-help tw-absolute tw-text-white -tw-top-2 tw-left-[calc(100%-0.5rem)] tw-font-mono tw-rounded-full tw-text-xs"
    >
      <b-tooltip append-to-body multilined type="is-dark">
        <template #default>
          <span class="tw-px-1.5 tw-h-5 tw-leading-none tw-grid tw-place-items-center">
            {{ order.status_info.length }}
          </span>
        </template>
        <template #content>
          <ol class="tw-font-sans tw-flex-col tw-flex tw-text-left tw-list-disc tw-list-outside tw-ml-4">
            <li v-for="(value, index) in order.status_info" :key="index">
              <span v-if="value.type === 'missing_shipment_logic_rule_value'">{{ value.message }}</span>
              <span v-else-if="value.type && value.field">
                {{ STATUS_INFO_MAPPING[`${value.type}_${value.field}`] }}
              </span>
              <span v-else-if="value.message">{{ value.message }}</span>
              <span v-else>Onbekend</span>
            </li>
          </ol>
        </template>
      </b-tooltip>
    </span>
  </div>
</template>

<script setup>
  import { STATUS_INFO_MAPPING, STATUS_MAPPING } from '@/constants/COMMON';
  import { computed } from 'vue';
  import { cn } from '@/utils/functions';

  const ERROR_STATUSES = ['error', 'incomplete'];
  const WARNING_STATUSES = ['warning'];
  const SUCCESS_STATUSES = ['ready', 'completed'];

  const props = defineProps({
    order: Object,
  });

  /** If the order has an error status, use that, otherwise use the regular status */
  const rawErrorStatus = computed(() => props.order.error_status || props.order.status);
  const isError = computed(() => props.order.error_status || ERROR_STATUSES.includes(rawErrorStatus.value));
  const isSuccess = computed(() => SUCCESS_STATUSES.includes(rawErrorStatus.value));

  const isWarning = computed(() => WARNING_STATUSES.includes(rawErrorStatus.value));

  const hasStatusInfo = computed(() => props.order.status_info.length > 0);

  const text = computed(() => STATUS_MAPPING['order'][props.order.status]?.tag || props.order.status || 'Onbekend');
  const tooltip = computed(() => STATUS_MAPPING['order'][props.order.status]?.tooltip);
</script>
